.local-tabs {
  margin: 15px 0;
}

.local-tabs .primary-tabs-menu {
  background-color: #f5f5f5;
}

.local-tabs .tabs__trigger {
  display: none;
  position: absolute;
  z-index: 10;
  right: 0;
  top: 0;
  left: auto;
  width: 15%;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  outline: 0;
  height: 40px;
  background-color: #e6e7e8;
}

.local-tabs .tabs__trigger svg {
  position: relative;
  top: -2px;
  max-width: 12px;
}

.local-tabs .tabs__trigger:hover, .local-tabs .tabs__trigger:focus {
  color: #121012;
  background-color: #e6e7e8;
}

.local-tabs .tabs__tab {
  position: relative;
  margin: 0;
  padding: 0;
  background-color: #e6e7e8;
}

.local-tabs .tabs__tab a {
  display: block;
  color: #29363E;
  text-decoration: none;
}

.local-tabs .tabs a:hover,
.local-tabs .tabs a:focus {
  text-decoration: none;
  outline: none;
}

.local-tabs .tabs.primary {
  margin: 0;
}

.local-tabs .tabs.primary li.is-active {
  background-color: #58585b;
}

.local-tabs .tabs.primary li.is-active a.is-active {
  color: #FFD400;
}

.local-tabs .tabs.secondary {
  margin-top: 15px;
}

.local-tabs .is-collapse {
  position: relative;
}

.local-tabs .is-collapse-enabled {
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .local-tabs .tabs__tab {
    overflow: hidden;
    display: block;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .local-tabs .tabs__tab a {
    padding: 5px 10px;
  }
  .local-tabs .tabs__tab:not(.is-active):hover {
    background-color: #58585b;
  }
  .local-tabs .tabs__tab:not(.is-active):hover a {
    color: #FFF;
  }
  .local-tabs .tabs.primary {
    overflow: hidden;
    padding-top: 40px;
    max-height: 0;
  }
  .local-tabs .tabs.primary .tabs__tab.is-active {
    z-index: 15;
    background-color: #EFEFEF;
  }
  .local-tabs .tabs.primary .tabs__tab.is-active a.is-active {
    color: #FFD400;
    height: 40px;
    line-height: 30px;
  }
  .local-tabs .tabs.primary a:focus,
  .local-tabs .tabs.primary .is-active a:focus {
    text-decoration: underline;
  }
  .local-tabs .is-collapse-enabled .tabs__trigger {
    display: block;
  }
  .local-tabs .primary.is-open {
    max-height: 999em;
    padding-bottom: 0;
  }
  .local-tabs .is-collapse-enabled .tabs__tab.is-active {
    position: absolute;
    top: 0;
    left: 0;
    width: 85%;
    height: 40px;
  }
  .local-tabs .is-collapse-enabled .tabs.primary a.is-active:before {
    content: none;
  }
}

@media screen and (min-width: 768px) {
  .local-tabs .tabs__tab {
    display: inline-block;
  }
  .local-tabs .primary-tabs-menu {
    background-color: transparent;
  }
  .local-tabs .tabs.primary {
    margin-top: -5px;
    padding: 0;
  }
  .local-tabs .tabs.primary .tabs__tab {
    margin-top: 5px;
  }
  .local-tabs .tabs.primary .tabs__tab a {
    display: block;
    font-weight: 400;
    padding: 5px 10px;
  }
  .local-tabs .tabs.primary .tabs__tab:not(.is-active):hover {
    background-color: #58585b;
  }
  .local-tabs .tabs.primary .tabs__tab:not(.is-active):hover a {
    color: #FFF;
  }
  .local-tabs .tabs.secondary {
    margin-top: 30px;
  }
}

@media screen and (min-width: 992px) {
  .local-tabs .tabs.primary .tabs__tab a {
    padding: 5px 20px;
  }
  .local-tabs .tabs.secondary {
    margin-top: 30px;
  }
}
