@import "./_sass-essentials/essentials.scss";

.local-tabs {
  margin: $l-gutter-half 0;

  .primary-tabs-menu {
    background-color: $c-lt-menu-bg-desk;
  }

  .tabs__trigger {
    display: none;
    position: absolute;
    z-index: 10;
    right: 0;
    top: 0;
    left: auto;
    width: 15%;
    padding-right: 0;
    padding-left: 0;
    text-align: center;
    outline: 0;
    height: 40px;
    background-color: $c-lt-trigger-bg;

    svg {
      position: relative;
      top: -2px;
      max-width: 12px;
    }

    &:hover,
    &:focus {
      color: $c-lt-trigger-txt-hov;
      background-color: $c-lt-trigger-bg-hov;
    }
  }

  .tabs__tab {
    position: relative;
    margin: 0;
    padding: 0;
    background-color: $c-lt-tab-bg;

    a {
      display: block;
      color: $c-lt-tab-link;
      text-decoration: none;
    }
  }

  .tabs {
    a:hover,
    a:focus {
      text-decoration: none;
      outline: none;
    }

    &.primary {
      margin: 0;

      li.is-active {
        background-color: $c-lt-prim-tab-bg;

        a.is-active {
          color: $c-lt-prim-tab-txt-active;
        }
      }
    }

    &.secondary {
      margin-top: $l-gutter-half;
    }
  }

  // JS dependent styling
  .is-collapse {
    position: relative;
  }

  .is-collapse-enabled {
    overflow: hidden;
  }


  @include mq(false, $bp-xs-max) {
    .tabs__tab {
      overflow: hidden;
      display: block;
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;

      a {
        padding: 5px 10px;
      }

      &:not(.is-active):hover {
        background-color: $c-xs-tab-bg-hov;

        a {
          color: $c-xs-tab-txt-hov;
        }
      }
    }

    .tabs {
      &.primary {
        overflow: hidden;
        padding-top: 40px;
        max-height: 0;

        .tabs__tab.is-active {
          z-index: 15;
          background-color: $c-xs-active-tab-bg;

          a.is-active {
            color: $c-xs-active-tab-txt;
            height: 40px;
            line-height: 30px;
          }
        }

        a:focus,
        .is-active a:focus {
          text-decoration: underline;
        }
      }
    }

    // JS dependent styling
    .is-collapse-enabled {
      .tabs__trigger {
        display: block;
      }
    }

    .primary.is-open {
      max-height: 999em;
      padding-bottom: 0;
    }

    .is-collapse-enabled {
      .tabs__tab.is-active {
        position: absolute;
        top: 0;
        left: 0;
        width: 85%;
        height: 40px;
      }

      .tabs.primary a.is-active:before {
        content: none;
      }
    }
  }

  @include mq($bp-sm-min) {
    .tabs__tab {
      display: inline-block;
    }

    .primary-tabs-menu {
      background-color: transparent;
    }

    .tabs {
      &.primary {
        margin-top: -5px;
        padding: 0;

        .tabs__tab {
          margin-top: 5px;

          a {
            display: block;
            font-weight: 400;
            padding: 5px 10px;
          }

          &:not(.is-active):hover {
            background-color: $c-sm-tab-bg-hov;

            a {
              color: $c-sm-tab-txt-hov;
            }
          }
        }
      }

      &.secondary {
        margin-top: $l-gutter;
      }
    }
  }

  @include mq($bp-md-min) {
    .tabs {
      &.primary {
        .tabs__tab {
          a {
            padding: 5px 20px;
          }
        }
      }

      &.secondary {
        margin-top: $l-gutter;
      }
    }
  }
}
